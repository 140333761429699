import {
  ConvoPromptSchema,
  ConvoSchema,
  ConvoSubsectionSchema
} from '~/models/conversation/SchemaModels'
import { PlanType } from '~/generated/api-clients-generated'

export const PlandalfFeedbackLoopSchema = (context?: any) =>
  new ConvoSchema({
    id: 'feedback-loop',
    context: context,
    prompts: [
      // new ConvoPromptSchema({
      //   id: 'view-details'
      // }),
      // new ConvoPromptSchema({
      //   id: 'compare-previous'
      // }),
      new ConvoPromptSchema({
        id: 'add-drugs',
        planTypes: [PlanType.MAPD, PlanType.PDP]
      }),
      new ConvoPromptSchema({
        id: 'add-doctors',
        planTypes: [PlanType.MAPD]
      }),
      new ConvoPromptSchema({
        id: 'add-benefits',
        planTypes: [PlanType.MAPD]
      }),
      new ConvoPromptSchema({
        id: 'sort-drug-costs',
        planTypes: [PlanType.MAPD, PlanType.PDP],
        subsection: new ConvoSubsectionSchema({
          id: 'get-recommendation',
          context: context
        })
      }),

      new ConvoPromptSchema({
        id: 'doctor-preferences',
        planTypes: [PlanType.MAPD],
        subsection: new ConvoSubsectionSchema({
          id: 'doctor-options',
          context: context
        })
      }),

      new ConvoPromptSchema({
        id: 'benefit-preferences',
        planTypes: [PlanType.MAPD],
        subsection: new ConvoSubsectionSchema({
          id: 'benefit-options',
          context: context
        })
      }),
      new ConvoPromptSchema({
        id: 'carrier-preferences',
        planTypes: [PlanType.MAPD, PlanType.PDP],
        subsection: new ConvoSubsectionSchema({
          id: 'carrier-options',
          context: context
        })
      }),
      new ConvoPromptSchema({
        id: 'sort-hmo',
        planTypes: [PlanType.MAPD],
        subsection: new ConvoSubsectionSchema({
          id: 'get-recommendation',
          context: context
        })
      }),
      new ConvoPromptSchema({
        id: 'sort-ppo',
        planTypes: [PlanType.MAPD],
        subsection: new ConvoSubsectionSchema({
          id: 'get-recommendation',
          context: context
        })
      })
    ]
  })

export const PlandalfRecommendationResponseSchema = (context?: any) =>
  new ConvoSchema({
    id: 'recommendation-response',
    context: context,
    prompts: [
      new ConvoPromptSchema({
        id: 'yes'
      }),
      new ConvoPromptSchema({
        id: 'no',
        subsection: PlandalfFeedbackLoopSchema({ schemaId: 'recommendation-response' })
      })
    ]
  })

export const PlandalfRunEngineSchema = (context?: any) =>
  new ConvoSchema({
    id: 'run-engine',
    context: context
  })

export const PlandalfGetRecommendationSchema = (context?: any) =>
  new ConvoSchema({
    id: 'get-recommendation',
    context: context
  })
