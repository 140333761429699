import type { Waypoint } from '~/generated/api-clients-generated'
import { WaypointSubType, WaypointType } from '~/generated/api-clients-generated'
import type { WaypointNode } from '~/models/journey/waypoints'
import PageNode from '~/models/journey/PageNode'
import { CreateProfileNode } from '~/models/journey/processing/CreateProfileNode'
import { IsNewToMedicareNode } from '~/models/journey/decision/IsNewToMedicareNode'
import { SetDefaultEngineParamsNode } from '~/models/journey/processing/SetDefaultEngineParamsNode'
import { RunCarrierFootprintNode } from '~/models/journey/processing/GetCarrierFootprintNode'
import { RunEngineNode } from '~/models/journey/processing/RunEngineNode'
import { SignUpRedirectNode } from '~/models/journey/redirect/SignUpRedirectNode'
import { SignInRedirectNode } from '~/models/journey/redirect/SignInRedirectNode'
import { QuoteDetailsRedirectNode } from '~/models/journey/redirect/QuoteDetailsRedirectNode'
import { IsShoppingForPackageNode } from '~/models/journey/decision/IsShoppingForPackageNode'
import { CoveragePrefsTestNode } from '~/models/journey/decision/CoveragePrefsTestNode'
import { HasPlanTypeRecommendationNode } from '~/models/journey/decision/HasPlanTypeRecommendationNode'
import { SkipPharmacyTestNode } from '~/models/journey/decision/SkipPharmacyTestNode'
import { ConnectureRedirectNode } from '~/models/journey/redirect/ConnectureRedirectNode'
import { JourneyHealthPreProcessNode } from '~/models/journey/processing/JourneyHealthPreProcessNode'
import { ExternalCarrierAppRedirectNode } from '~/models/journey/redirect/ExternalCarrierAppRedirectNode'
import { IsAuthenticatedNode } from '~/models/journey/decision/IsAuthenticatedNode'
import { SelectedCoverageTypeNode } from '~/models/journey/decision/SelectedCoverageTypeNode'
import { CoverageTypeOrPlanTypeNode } from '~/models/journey/decision/CoverageTypeOrPlanTypeNode'
import { ShowTobaccoQuestionNode } from '~/models/journey/decision/ShowTobaccoQuestionNode'
import { CanChooseYourOwnAdventureNode } from '~/models/journey/decision/CanChooseYourOwnAdventureNode'
import { ProhibitImportNode } from '~/models/journey/decision/ProhibitImportNode'
import { SkipSpecialistRefTest } from '~/models/journey/decision/SkipSpecialistRefTest'
import { ShowBirthdateQuestionNode } from '~/models/journey/decision/ShowBirthdateQuestionNode'
import { ShowGenderQuestionNode } from '~/models/journey/decision/ShowGenderQuestionNode'
import { IsMsPlanTypeNode } from '~/models/journey/decision/IsMsPlanTypeNode'
import { ShowGettingStartedNode } from '~/models/journey/decision/ShowGettingStartedNode'
import { SetDefaultGoalsNode } from '~/models/journey/processing/SetDefaultGoalsNode'
import { ShowMedicaidQuestionNode } from '~/models/journey/decision/ShowMedicaidQuestionNode'
import { ShowDefaultSortNode } from '~/models/journey/decision/ShowDefaultSortNode'
import { GetPlanTypeRecommendationNode } from '~/models/journey/processing/GetPlanTypeRecommendationNode'
import { LimitedPlanTypeSupplyNode } from '~/models/journey/decision/LimitedPlanTypeSupplyNode'
import { AwaitFootprintNode } from '~/models/journey/processing/AwaitFootprintNode'
import { IsRxPlanTypeNode } from '~/models/journey/decision/IsRxPlanTypeNode'
import { ShowIepContextNode } from '~/models/journey/decision/ShowIepContextNode'
import { RouteIepResultNode } from '~/models/journey/decision/RouteIepResultNode'
import { CompleteIepEditsNode } from '~/models/journey/processing/CompleteIepEditsNode'
import { CancelIepEditsNode } from '~/models/journey/processing/CancelIepEditsNode'
import { SamePlanRecommendedNode } from '~/models/journey/decision/SamePlanRecommendedNode'
import { CheckupDuringAepNode } from '~/models/journey/decision/CheckupDuringAepNode'
import { CurrentPlanKnownNode } from '~/models/journey/decision/CurrentPlanKnownNode'
import { SetCheckupEngineNode } from '~/models/journey/processing/SetCheckupEngineNode'
import { CreateCheckupNode } from '~/models/journey/processing/CreateCheckupNode'
import { CurrentPlanIsPdpNode } from '~/models/journey/decision/CurrentPlanIsPdpNode'
import { NoAccountEnrollNode } from '~/models/journey/decision/NoAccountEnrollNode'
import { IsLocationNeededNode } from '~/models/journey/decision/IsLocationNeededNode'
import IsAepNode from '~/models/journey/decision/IsAepNode'
import IsPreshopNode from '~/models/journey/decision/IsPreshopNode'

export default class NodeFactory {
  static create(waypoint: Waypoint): WaypointNode {
    switch (waypoint.type) {
      case WaypointType.Page:
        return new PageNode(waypoint)
      default:
        switch (waypoint.subType) {
          // Decision
          case WaypointSubType.IsNewToMedicare:
            return new IsNewToMedicareNode()
          case WaypointSubType.IsShoppingForPackage:
            return new IsShoppingForPackageNode()
          case WaypointSubType.CoveragePrefsTest:
            return new CoveragePrefsTestNode()
          case WaypointSubType.IsAuthenticated:
            return new IsAuthenticatedNode()
          case WaypointSubType.SelectedCoverageType:
            return new SelectedCoverageTypeNode()
          case WaypointSubType.CoverageTypeOrPlanType:
            return new CoverageTypeOrPlanTypeNode()
          case WaypointSubType.ShowTobaccoQuestion:
            return new ShowTobaccoQuestionNode()
          case WaypointSubType.CanChooseYourOwnAdventure:
            return new CanChooseYourOwnAdventureNode()
          case WaypointSubType.ProhibitImport:
            return new ProhibitImportNode()
          case WaypointSubType.SkipSpecialistRefTest:
            return new SkipSpecialistRefTest()
          case WaypointSubType.ShowBirthdateQuestion:
            return new ShowBirthdateQuestionNode()
          case WaypointSubType.ShowGenderQuestion:
            return new ShowGenderQuestionNode()
          case WaypointSubType.IsMsPlanType:
            return new IsMsPlanTypeNode()
          case WaypointSubType.ShowGettingStarted:
            return new ShowGettingStartedNode()
          case WaypointSubType.ShowMedicaidQuestion:
            return new ShowMedicaidQuestionNode()
          case WaypointSubType.ShowDefaultSort:
            return new ShowDefaultSortNode()
          case WaypointSubType.LimitedPlanTypeSupply:
            return new LimitedPlanTypeSupplyNode()
          case WaypointSubType.IsRxPlanType:
            return new IsRxPlanTypeNode()
          case WaypointSubType.SamePlanRecommended:
            return new SamePlanRecommendedNode()
          case WaypointSubType.CheckupDuringAep:
            return new CheckupDuringAepNode()
          case WaypointSubType.CurrentPlanKnown:
            return new CurrentPlanKnownNode()
          case WaypointSubType.CurrentPlanIsPdp:
            return new CurrentPlanIsPdpNode()
          case WaypointSubType.NoAccountEnroll:
            return new NoAccountEnrollNode()
          case WaypointSubType.IsLocationNeeded:
            return new IsLocationNeededNode()
          case WaypointSubType.IsAep:
            return new IsAepNode()
          case WaypointSubType.IsPreshop:
            return new IsPreshopNode()
          // Processing
          case WaypointSubType.CreateProfile:
            return new CreateProfileNode()
          case WaypointSubType.SetDefaultEngineParams:
            return new SetDefaultEngineParamsNode()
          case WaypointSubType.RunCarrierFootprint:
            return new RunCarrierFootprintNode()
          case WaypointSubType.RunEngine:
            return new RunEngineNode()
          case WaypointSubType.SetDefaultGoals:
            return new SetDefaultGoalsNode()
          case WaypointSubType.AwaitFootprint:
            return new AwaitFootprintNode()
          case WaypointSubType.SetCheckupEngine:
            return new SetCheckupEngineNode()
          case WaypointSubType.CreateCheckup:
            return new CreateCheckupNode()
          // Redirect
          case WaypointSubType.SignUpRedirect:
            return new SignUpRedirectNode()
          case WaypointSubType.SignInRedirect:
            return new SignInRedirectNode()
          case WaypointSubType.QuoteDetailsRedirect:
            return new QuoteDetailsRedirectNode()
          case WaypointSubType.HasPlanTypeRecommendation:
            return new HasPlanTypeRecommendationNode()
          case WaypointSubType.JourneyHealthPreProcess:
            return new JourneyHealthPreProcessNode()
          case WaypointSubType.SkipPharmacyTest:
            return new SkipPharmacyTestNode()
          case WaypointSubType.ConnectureRedirect:
            return new ConnectureRedirectNode()
          case WaypointSubType.ExternalCarrierAppRedirect:
            return new ExternalCarrierAppRedirectNode()
          case WaypointSubType.GetPlanTypeRecommendation:
            return new GetPlanTypeRecommendationNode()
          case WaypointSubType.ShowIepContext:
            return new ShowIepContextNode()
          case WaypointSubType.RouteIepResult:
            return new RouteIepResultNode()
          case WaypointSubType.CompleteIepEdits:
            return new CompleteIepEditsNode()
          case WaypointSubType.CancelIepEdits:
            return new CancelIepEditsNode()
          default:
            console.log('Missing node implementation for subtype', waypoint.subType)
            throw new Error(`Missing node implementation for subtype ${waypoint.subType}`)
        }
    }
  }
}
