import { PlanType } from '~/generated/api-clients-generated'
import { EngineStore } from '~/stores/engine'
import type { CamelToSnakeCase } from '~/types/util'
import { reactiveComputed } from '@vueuse/shared'

export type QuotingFeature = 'collapsedQuotes' | 'globalAdd' | 'planFinder' | 'limitedChoice' | 'surfacedCompare' | 'emptyAddNeeds'
export type QuotingFeatureConfigKey = CamelToSnakeCase<QuotingFeature>

export default class QuotingConfig {
  static use() {
    const { flag } = FeatureFlags.use()

    const engine = EngineStore.use()

    const mapdConfig = computed(() => flag<Record<QuotingFeatureConfigKey, any>>('mapd-quoting-config').value)
    const pdpConfig = computed(() => flag<Record<QuotingFeatureConfigKey, any>>('pdp-quoting-config').value)

    const typeConfig = computed(() => engine.selectedPlanType === PlanType.PDP
      ? pdpConfig.value
      : mapdConfig.value)

    const globalAddShouldStick = computed(() => (engine.params.doctors?.length ?? 0) < 1
      && (engine.params.rxs?.length ?? 0) < 1 && ((engine.params.additionalBenefits?.length ?? 0)< 1))

    const quoteCardVariants = computed(() => ({
      'default': defineAsyncComponent(() => import('~/components/quote/card/QuoteCardDefault.vue'))
    }))

    const quoteCardVariant = computed(() => quoteCardVariants.value['default'])

    const config = computed<Record<QuotingFeature, any>>(() =>
      Object.keys(typeConfig.value)
        .reduce((agg, curr) =>
          ({...agg, [_camelCase(curr)]: typeConfig.value[curr as QuotingFeatureConfigKey]}),
          {} as Record<QuotingFeature, any>)
    )

    const quotingConfig = computed(() => ({
      ...config.value,
      globalAddShouldStick: globalAddShouldStick.value,
      quoteCardVariants: quoteCardVariants.value,
      quoteCardVariant: quoteCardVariant.value
    }))

    return {
      quotingConfig
    }
  }
}