import { EngineStore } from '~/stores/engine'
import { SessionStore } from '~/stores/session'
import { PlanType, PolicyType } from '~/generated/api-clients-generated'
import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

export interface PlanListConfig {
  gap: PlanListConfigItem[]
  mapd: PlanListConfigItem[]
  pdp: PlanListConfigItem[]
}

export interface PlanListConfigItem {
  id: PlanList
  text: string
  conditions?: (
    | 'hasProfileItems'
    | 'noProfileItems'
    | 'hasDoctors'
    | 'noDoctors'
    | 'hasDrugs'
    | 'noDrugs'
    | 'hasPharmacyAssociation'
  )[]
}

export type PlanPredicate = (quote: QuotedPlanVm) => boolean

export type ListFilter = (plans: QuotedPlanVm[]) => QuotedPlanVm[]

export type PlanList =
  | 'all'
  | 'bestForYou'
  | 'bestDoctorCoverage'
  | 'fiveStarPlans'
  | 'topByCarrier'
  | 'ppo'
  | 'zeroPremium'
  | 'cheapestPlans'
  | 'preferredPharmacy'

export default class PlanLists {
  static use() {
    const { flag } = FeatureFlags.use()
    const engine = EngineStore.use()
    const session = SessionStore.use()
    const { limitedChoiceSet } = LimitedChoice.use()
    const { quotingConfig } = QuotingConfig.use()
    const { defaultSort, currentSort } = QuoteUtils.useSort()
    const limitedChoiceView = computed(() => quotingConfig.value.limitedChoice === true && currentSort.value === defaultSort.value)

    const applicableQuotes = computed(() => {
      if (limitedChoiceView) {
        return engine.availableQuotes.filter(
          (q) => !limitedChoiceSet.value.map((lcq) => lcq.medicareId).includes(q.medicareId)
        )
      }
      return engine.availableQuotes
    })

    const config = computed(() => flag<PlanListConfig>('quick-plan-lists').value)

    const conditions = computed(() => ({
      hasProfileItems() {
        return !engine.isEmptyState
      },
      noProfileItems() {
        return engine.isEmptyState
      },
      hasDoctors() {
        return engine.params.doctors?.length ?? 0 > 0
      },
      noDoctors() {
        return _isNil(engine.params.doctors) || engine.params.doctors.length < 1
      },
      hasDrugs() {
        return engine.params.rxs?.length ?? 0 > 0
      },
      noDrugs() {
        return _isNil(engine.params.rxs) || engine.params.rxs.length < 1
      },
      hasPharmacyAssociation() {
        return !_isNil(session.associatedPharmacy)
      }
    }))

    const currentConfig = computed(() =>
      config.value[
        PlanType[session?.planType ?? PlanType.MAPD].toString().toLowerCase() as
          | 'gap'
          | 'mapd'
          | 'pdp'
      ]
        .filter((i) => _isNil(i.conditions) || i.conditions.every((c) => conditions.value[c]()))
        .filter(
          (i) => this.applyList(i.id, applicableQuotes.value as QuotedPlanVm[], false).length > 0
        )
        .map((i) => ({
          text: i.text,
          value: i.id
        }))
    )

    return {
      planLists: currentConfig
    }
  }

  static applyList(list: PlanList, plans: QuotedPlanVm[], track: boolean = true): QuotedPlanVm[] {
    const fn = this.listFns[list]

    if (track) {
      const { track: trackList } = Analytics.use()
      trackList('PlanListApplied', {
        name: list
      })
    }

    return ['topByCarrier', 'cheapestPlans'].includes(list)
      ? (fn as ListFilter)(plans)
      : plans.filter(fn as PlanPredicate)
  }

  static listFns: Record<PlanList, PlanPredicate | ListFilter> = {
    all(quote: QuotedPlanVm): boolean {
      return true
    },
    bestForYou(quote: QuotedPlanVm): boolean {
      return quote.score > 82
    },
    bestDoctorCoverage(quote: QuotedPlanVm): boolean {
      return quote.providerCoverage.inNetworkCount / quote.providerCoverage.count >= 0.75
    },
    fiveStarPlans(quote: QuotedPlanVm): boolean {
      return quote.details.starRating === 5
    },
    ppo(quote: QuotedPlanVm): boolean {
      return [PolicyType.PPO, PolicyType.LPPO, PolicyType.RPPO].includes(quote.policyType)
    },
    zeroPremium(quote: QuotedPlanVm): boolean {
      return quote.details.premium! <= 0
    },
    preferredPharmacy(quote: QuotedPlanVm): boolean {
      return QuoteUtils.hasPreferredPharmacy(quote)
    },
    topByCarrier(plans: QuotedPlanVm[]): QuotedPlanVm[] {
      return _uniqBy(
        [...plans].sort((a, b) => b.score - a.score),
        (q) => q.details.carrierFilterKey
      )
    },
    cheapestPlans(plans: QuotedPlanVm[]): QuotedPlanVm[] {
      const limit = percentile(
        plans.map((p) => p.drugCost + p.recommendation.annualPremium),
        25
      )
      return plans.filter((p) => p.drugCost + p.recommendation.annualPremium < limit)
    }
  }
}
